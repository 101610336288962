import { Avatar, Box, Flex, Heading, Spacer, Tag, Text } from "@chakra-ui/react"

import { Location, useLocation, useNavigate } from "@remix-run/react"
import { HiOutlineUser } from "react-icons/hi"
import { getPeriodOfDate } from "~/utils/datetime"
import { getUserAvatarSrc } from "~/utils/getUserAvatarSrc"
import { useBgColor } from "~/utils/hooks/useBgColor"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { isNullish } from "~/utils/values"
import { SearchInput } from "../SearchInput"
import { AccessTag } from "../SideNavigation/AccessTag"

type Route = string | { [key: string]: Route }

const PAGE_TITLES: Exclude<Route, string> = {
  "/": "Home",
  "/account": "Account Settings",
  "/import": "Import List",
  "/admin": {
    "/debug": "Debug",
    "/permissions": "Access Management",
    "/global-hub": "Global Hub",
    "/companies": "Add Companies",
    "/integration-companies": "Integration Companies",
  },
  "/signals": {
    "/strategic": "Strategic Signals",
    "/talent": "Talent Signals",
    "/company": "Company Signals",
    "/investors": "Investors",
    "/funding-rounds": "Funding Rounds",
    "/acquisition": "Acquisitions",
    "/ipo": "IPOs",
  },
  "/user": {
    "/lists": {
      "/company": "Company Lists",
      "/talent": "Talent Lists",
      "/strategic": "Strategic Lists",
    },
    "/searches": {
      "/company": "Company Searches",
      "/talent": "Talent Searches",
      "/strategic": "Strategic Searches",
    },
    "/landscapes": "Landscapes",
  },
  "/team": "Team Settings",
}

export const NEW_FEATURED_ROUTES = ["/user/landscapes"]
export const BETA_FEATURED_ROUTES = ["/investors", "chromewebstore.google.com"]

export const HEADER_HEIGHT = 50

const getSearchPlaceholder = (pathname: string) => {
  if (pathname.includes("/signals/funding-rounds")) {
    return "Search Companies or Investors"
  }

  if (pathname.includes("/signals/investors")) {
    return "Search Investors"
  }

  if (pathname.includes("/signals/acquisition")) {
    return "Search Acquisitions"
  }

  if (pathname.includes("/signals/ipo")) {
    return "Search Companies"
  }

  if (pathname.includes("/signals/strategic")) {
    return "Search Companies or People"
  }

  return "Search Companies or Domains"
}

const getRootPath = (url: string) => {
  const constructPath = (path: string) => url.split(path)[0] + path
  return constructPath(url.includes("table") ? "table" : "feed")
}

export const Header = (): JSX.Element => {
  const [searchParams] = useSafeSearchParams()
  const bgColor = useBgColor()
  const location = useLocation()
  const permissions = useUserPermissions()
  const firstName = permissions.data?.first_name
    ? `, ${permissions.data.first_name}`
    : ""
  const navigate = useNavigate()

  const fullName = `${permissions.data?.first_name} ${permissions.data?.last_name}`

  const pageTitle =
    location.pathname === "/"
      ? `Good ${getPeriodOfDate(new Date())}${firstName}`
      : getPageTitle(location)

  const isNew = NEW_FEATURED_ROUTES.some((route) =>
    location.pathname?.includes(route)
  )

  const showSearchInput =
    location.pathname.includes("/signals/company") ||
    location.pathname.includes("/signals/funding-rounds") ||
    location.pathname.includes("/signals/investors") ||
    location.pathname.includes("/signals/acquisition") ||
    location.pathname.includes("/signals/ipo") ||
    location.pathname.includes("/signals/strategic")

  return (
    <Flex
      as="header"
      className="grid-area-header"
      position="sticky"
      top={0}
      transition="all 0.2s"
      alignItems="center"
      borderBottomWidth={1}
      borderColor="gray.100"
      px={6}
      h={`${HEADER_HEIGHT}px`}
      bg={bgColor}
      zIndex={200}
      borderTopRadius="xl"
      gap={2}
    >
      <Heading
        fontSize="md"
        fontWeight="semibold"
        display="inline-flex"
        alignItems="center"
        gap={3}
      >
        {pageTitle}
        {isNew && pageTitle && <Tag colorScheme="brand">New</Tag>}
      </Heading>

      <Spacer />

      {showSearchInput && (
        <Box
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-60%, -50%)"
        >
          <SearchInput
            initial={searchParams.get("search")}
            placeholder={getSearchPlaceholder(location.pathname)}
            onChange={(searchTerm) => {
              if (searchTerm || searchParams.has("search")) {
                if (searchTerm) {
                  searchParams.set("search", searchTerm)
                } else {
                  searchParams.delete("search")
                }
                navigate(
                  `${getRootPath(location.pathname)}?${searchParams.toString()}`
                )
              }
            }}
          />
        </Box>
      )}

      <Box pr={2}>
        <AccessTag />
      </Box>

      <Avatar
        src={getUserAvatarSrc(permissions.data?.avatar)}
        icon={<HiOutlineUser />}
        fontWeight="semibold"
        name={fullName}
        boxSize="34px"
        borderRadius="full"
      />
      <Box>
        <Text
          fontSize="xs"
          fontWeight="semibold"
          lineHeight={1}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {fullName}
        </Text>
        <Text
          fontSize="xx-small"
          color="gray.500"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {permissions.data?.email}
        </Text>
      </Box>
    </Flex>
  )
}

export const getPageTitle = (location: Location): string => {
  const path = location.pathname

  if (path === "/") {
    return PAGE_TITLES["/"] as string
  }

  const pathParts = path.split("/").filter((part) => part !== "")

  return getFromTree(PAGE_TITLES, pathParts, "")
}

const getFromTree = (
  tree: Exclude<Route, string>,
  pathParts: string[],
  fallback: string
): string => {
  const [part, ...rest] = pathParts

  if (isNullish(part)) {
    return fallback
  }

  const nextTree = tree[`/${part}`]

  if (isNullish(nextTree)) {
    return fallback
  }

  if (typeof nextTree === "string") {
    return nextTree
  }

  const newFallback =
    typeof nextTree["/"] === "string" ? nextTree["/"] : fallback

  return getFromTree(nextTree, rest, newFallback)
}
